*,
*:before,
*:after {
    box-sizing: border-box;
    font-size: 1rem;
    line-height: 1.333rem;
    font-family: --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    word-wrap: break-word;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    padding: 0;
    margin: 0;
}

body {
    background: url('/backdrop.png') no-repeat, hsl(0, 0%, 8%);
    background-position: 1rem 1rem;
    background-size: 470px;
}

#container {
}

nav {
    position: relative;
    min-width: 100vw;
    height: 40px;
    background-image: linear-gradient(
        180deg,
        hsla(330, 60%, 60%, 0.92) 0%,
        hsla(330, 60%, 40%, 0.92) 100%
    );
    z-index: 100;
    box-shadow: 0 0 6px hsl(0, 0%, 0%);
    border-bottom: 1px solid hsla(330, 60%, 50%, 0.3);
}

nav ul {
    display: flex;
    justify-content: left;
    list-style-type: none;
    padding-left: 1rem;
}

nav li {
    padding-right: 1rem;
}

nav li a,
nav li a:visited {
    color: hsla(0, 0%, 100%, 0.4);
    text-decoration: none;
    border-bottom: 2px transparent;
    font-weight: 700;
    line-height: 40px;
    font-size: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: border-bottom 150ms ease-in;
    padding: 0.25rem 0 0.1rem;
}

nav li a:hover {
    border-bottom: 2px solid hsla(0, 0%, 100%, 0.4);
}

nav li a.active {
    color: hsla(0, 0%, 100%, 1);
    border-bottom: 2px solid hsla(0, 0%, 100%, 1);
}

header {
    min-width: 100vw;
    height: 136px;
    display: grid;
    justify-content: center;
    background-image: linear-gradient(
        180deg,
        hsla(0, 0%, 20%, 0.92) 0%,
        hsla(0, 0%, 8%, 0.92) 100%
    );
}

#logo {
    padding-top: 1.5rem;
    justify-self: center;
    padding-bottom: 1rem;
}

form {
    display: flex;
    flex-direction: row;
}

/* https://fabriceleven.com/design/clever-way-to-change-the-drop-down-selector-arrow-icon/ */

select::-ms-expand {
    display: none;
}

/* https://stackoverflow.com/questions/5780109/removing-rounded-corners-from-a-select-element-in-chrome-webkit */

.formInput,
.selectInput,
.formRating,
.formButton,
.formInput:visited,
.selectInput:visited,
.formRating:visited,
.formButton:visited {
    font-size: 1rem;
    font-weight: 400;
    color: hsla(0, 0%, 100%, 0.5);
    background: hsla(0, 0%, 8%, 0.92);
    width: 290px;
    height: 40px;
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    box-shadow: 0 3px 6px 0 hsla(0, 0%, 0%, 0.4);
    border: 1px solid hsla(0, 0%, 30%, 1);
    appearance: none;
    -webkit-appearance: none;
    border-radius: 1px;
    -webkit-border-radius: 1px;
    position: relative;
    transition: color 100ms ease-in, border 100ms ease-in;
}

.selectInput {
    background: url('/arrow.png') no-repeat right 50%, hsla(0, 0%, 8%, 0.92);
    background-size: 22px 18px, auto;
}

.formRating {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 188px;
}

.formRating span {
    cursor: pointer;
}

.formRating span:hover {
    color: hsl(330, 100%, 60%);
}

.formRating img:first-of-type {
    margin-left: 0.5rem;
}

.formRating img {
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.formRating img:hover {
    position: relative;
    top: -1px;
    border: 2px solid transparent;
}

.formRating:hover {
    border-color: hsl(0, 0%, 50%);
}

.formButton {
    width: 110px;
    font-weight: 600;
    background-image: linear-gradient(
        180deg,
        hsl(0, 0%, 20%) 0%,
        hsl(0, 0%, 8%) 100%
    );
    cursor: pointer;
}

.formButton:disabled {
    cursor: not-allowed;
}

.formInput:hover,
.selectInput:hover,
.formRating:hover {
    border-color: hsl(0, 0%, 50%);
}

.formButton:hover {
    border-color: hsl(330, 100%, 50%);
    color: hsl(330, 100%, 50%);
}

.formInput:focus,
.selectInput:focus,
.formRating:focus,
.formButton:focus {
    outline: none;
    border-color: hsl(330, 100%, 50%);
}

.formInput::placeholder,
.selectInput::placeholder {
    color: hsl(0, 0%, 100%, 0.3);
}

.formButton:active {
    position: relative;
    top: 2px;
}

/* https://webagility.com/posts/the-ultimate-list-of-hacks-for-chromes-forced-yellow-background-on-autocompleted-inputs */
.formInput:-webkit-autofill,
.formInput:-webkit-autofill:hover,
.formInput:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 100px hsl(330, 100%, 50%) inset;
    -webkit-text-fill-color: hsla(0, 0%, 100%, 1);
    font-weight: 500;
    border-color: hsl(330, 100%, 50%);
}

main {
    min-width: 100vw;
    display: grid;
    justify-content: center;
    padding-right: 0.5rem;
    background-color: hsla(0, 0%, 8%, 0.92);
    padding-bottom: 300px;
}

table {
    min-width: 1200px;
    height: 0px;
    box-shadow: 0 3px 6px 0 hsla(0, 0%, 0%, 0.4);
    border-spacing: 0;
    border: 1px solid hsla(0, 0%, 30%, 1);
}

th,
td {
    height: 40px;
    color: hsl(0, 0%, 70%);
    text-align: left;
    padding: 0 1rem;
    width: 25%;
}

tbody tr:nth-child(even) {
    background-color: hsla(0, 0%, 8%, 0.5);
}

tbody tr:nth-child(odd) {
    background-color: hsla(0, 0%, 20%, 0.5);
}

thead tr {
    background-color: hsla(0, 0%, 8%, 0.5);
}

thead th {
    font-size: 0.75rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 500;
    color: hsl(0, 0%, 50%);
    cursor: pointer;
}

tbody tr td:last-of-type {
    position: relative;
}

tbody tr td img {
    position: absolute;
    right: 11px;
    top: 11px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

tbody tr td img:hover {
    top: 10px;
    border: 1px solid transparent;
}

.sortIcon {
    width: 11px;
    height: 8px;
    margin-left: 0.25rem;
}

article {
    margin: -2.85rem 2rem 0 2rem;
    max-width: 800px;
    color: hsla(0, 0%, 100%, 0.5);
}

article h1 {
    font-size: 1.75rem;
    line-height: 2rem;
    color: hsla(0, 0%, 100%, 0.85);
}

article h2 {
    font-size: 1.75rem;
    line-height: 2rem;
    color: hsla(0, 0%, 100%, 0.85);
    margin-top: 4rem;
}

article p {
    font-weight: 600;
    margin-top: 2rem;
    font-size: 1.15rem;
    line-height: 1.7rem;
    margin-bottom: 1rem;
    color: hsla(0, 0%, 100%, 0.85);
}

article p:last-of-type {
    font-weight: 400;
    color: hsla(0, 0%, 100%, 0.5);
}

article li {
    margin-left: 1.2rem;
    font-size: 1.15rem;
    line-height: 1.7rem;
}
